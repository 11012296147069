.main {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.pokedexList {
  border: 3px solid blue;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.pokedexEntry {
  border: 3px solid red;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
}

.entryDataContainer {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.movesList {
  overflow: scroll;
  padding: 10px 20px;
  width: 100%;
}

.searchUnit {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.searchInput {
  width: 80%;
  height: 1em;
  font-family: GBC;
  resize: none;
}

.header {
  margin-top: 10px;
}

.entry {
  border: 2px solid black;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

.entry:hover {
  background-color: #f5f5f5;
}

.entry:active {
  background-color: #d3d3d3;
}

.sprite {
  max-height: 100px;
  align-self: center;
}

.evolution {
  text-decoration: underline;
  cursor: pointer;
}

.recentlyViewed {
  margin-top: 10px;
  font-size: 10px;
}

.searchHistory {
  overflow: scroll;
  max-height: 7em;
  padding: 5px 20px;
}

.historyItem {
  text-decoration: underline;
  margin: 5px 0px;
  cursor: pointer;
}

.searchResults {
  flex: 2;
  overflow: scroll;
}

.pokeballIcon {
  margin-right: 10px;
}

@font-face {
  font-family: 'GBC';
  src:
    local('GBC'),
    url('./fonts/PokemonGBC.ttf') format('truetype');
}
